import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { AddProductComponent } from './manage-product/add-product/add-product.component';
import { EditProductComponent } from './manage-product/edit-product/edit-product.component';
import { OrderDetailsComponent } from './order/order-details/order-details.component';
import { ProductListComponent } from './manage-product/product-list/product-list.component';
import { AddCustomerComponent } from './customer/add-customer/add-customer.component';
import { EditCustomerComponent } from './customer/edit-customer/edit-customer.component';

const routes: Routes = [
  {path: '', component: HeaderComponent},
  {path: 'order-details', component: OrderDetailsComponent},
  {path: 'add-product', component: AddProductComponent},
  {path: 'edit-product', component: EditProductComponent}, 
  {path: 'product-list', component: ProductListComponent},  
  {path: 'add-customer', component: AddCustomerComponent},  
  {path: 'edit-customer', component: EditCustomerComponent},  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
