import { Component } from '@angular/core';
import { Toast } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'edit-customer',
  templateUrl: './edit-customer.component.html',
  styleUrls: ['./edit-customer.component.css']
})
export class EditCustomerComponent {
  value = '';
  error = false;
  numberFocus = {
    focus: false,
    date: new Date()
  };
  inputFocus = {
    focus: false,
    date: new Date()
  };

  titleFocus = {
    focus: false,
    date: new Date()
  };
  autoFocus = { focus: true, date: new Date() };

  constructor(private _toast: Toast) {}

  public is_switch20: boolean = false;
  public is_switch25: boolean = false;
  public is_switch5: boolean = false;
  public is_switch2: boolean = false;
  public is_switch1: boolean = false;

  switch20(value) {
    this.is_switch20 = !this.is_switch20;
    console.log('switch status:', value);
  }
  switch25(value) {
    this.is_switch25 = !this.is_switch25;
    console.log('switch status:', value);
  }
  switch5(value) {
    this.is_switch5 = !this.is_switch5;
    console.log('switch status:', value);
  }
  switch2(value) {
    this.is_switch2 = !this.is_switch2;
    console.log('switch status:', value);
  }
  switch1(value) {
    this.is_switch1 = !this.is_switch1;
    console.log('switch status:', value);
  }

  inputErrorClick(e) {
    Toast.info('Please enter 11 digits');
  }

  inputChange(e) {
    const value = e.replace(/\s/g, '');
    if (value.length < 11 && value.length > 0) {
      this.error = true;
    } else {
      this.error = false;
    }
    this.value = e;
  }

  clickFocus() {
    this.numberFocus = {
      focus: true,
      date: new Date()
    };
  }

  clickFocusInput() {
    this.inputFocus = {
      focus: true,
      date: new Date()
    };
  }

  clickTitle() {
    this.titleFocus = {
      focus: true,
      date: new Date()
    };
  }
}
