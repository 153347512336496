import { Component, ElementRef, Renderer } from '@angular/core';

@Component({
  selector: 'product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent {
  disabled: boolean = false;
  renderFooter: Function;

// SEARCH
  value: string = 'OK';
  autoFocus = {
    focusValue: true
  };
  focusObj = {
    focusValue: false,
    date: new Date()
  };
  productList:any;
  constructor(private _element: ElementRef, private _renderer: Renderer) {


    this.productList = [
      {
        name: '20 Liter Can',
        stock: '500',
        price:'Rs.50'
      },
      {
        name: '25 Liter Jar',
        stock: '500',
        price:'Rs.50'
      },
      {
        name: '5 Liter Bottle',
        stock: '500',
        price:'Rs.20'
      },
      {
        name: '1 Liter Bottle',
        stock: '500',
        price:'Rs.10'
      },
    ]
  }

  renderHeader() {
    return 'Basic Style';
  }

  renderHeader1() {
    return 'Subtitle';
  }

  renderHeader2() {
    return 'Customized Right Side（Empty Content / Text / Image）';
  }

  renderHeader3() {
    return 'Align Vertical Center';
  }

  renderHeader4() {
    return 'Icon in the left';
  }

  renderHeader5() {
    return 'Text Wrapping';
  }

  renderHeader6() {
    return 'Other';
  }

  onClick() {
    console.log('click');
  }

  switchCheck(value) {
    console.log('switch status:', value);
  }

  onDisableClick() {
    console.log('click', this.disabled);
    this.disabled = true;
  }

  // for serch
  change($event) {
    console.log($event, 'onChange');
  }

  submit(value) {
    console.log(value, 'onSubmit');
  }

  clear(value) {
    console.log(value, 'onClear');
  }

  focus() {
    console.log('onFocus');
  }

  blur() {
    console.log('onBlur');
  }

  cancel() {
    console.log('onCancel');
  }

  handleClick() {
    this.focusObj = {
      focusValue: true,
      date: new Date()
    };
  }

}
