import { Component } from '@angular/core';  

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent {
  disabled: boolean = false;
  renderFooter: Function;
  productSummaryData:any;

  constructor() {
    this.productSummaryData = [
      {
        id: '#HFS23132JDF',
        name: 'Ganesh Borse',
        mobile: '+91 9876543210',
        order: '20 Liter Can',
        type: 'Chill Water',
        quantity: '3 Jar',
        time:'8:00 - 10:00 (AM)',
        mode: 'Cash on Delivery',
        price: 'Rs. 150',
        security: 'Rs. 500',
        count:'(50x3)',
        payment: 'Rs. 600',
        note: 'Angular is running in the development mode.',
      }
    ]}

  renderHeader() {
    return 'Basic Style';
  }

  renderHeader1() {
    return 'Subtitle';
  }

  renderHeader2() {
    return 'Customized Right Side（Empty Content / Text / Image）';
  }

  renderHeader3() {
    return 'Align Vertical Center';
  }

  renderHeader4() {
    return 'Icon in the left';
  }

  renderHeader5() {
    return 'Text Wrapping';
  }

  renderHeader6() {
    return 'Other';
  }

  onClick() {
    console.log('click');
  }

  switchCheck(value) {
    console.log('switch status:', value);
  }

  onDisableClick() {
    console.log('click', this.disabled);
    this.disabled = true;
  }
}
