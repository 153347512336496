import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgZorroAntdMobileModule, ModalServiceComponent, ActionSheetComponent, ToastComponent } from 'ng-zorro-antd-mobile';
import { HeaderComponent } from './header/header.component';
import { NavtabsComponent } from './header/navtabs/navtabs.component';
import { NavComponent } from './header/nav/nav.component';
import { SubHeaderComponent } from './header/sub-header/sub-header.component';
import { SidebarComponent } from './header/sidebar/sidebar.component';
import { AddProductComponent } from './manage-product/add-product/add-product.component';
import { EditProductComponent } from './manage-product/edit-product/edit-product.component';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderDetailsComponent } from './order/order-details/order-details.component';
import { PaymentComponent } from './payment/payment.component';
import { ProductListComponent } from './manage-product/product-list/product-list.component';
import { AddCustomerComponent } from './customer/add-customer/add-customer.component';
import { EditCustomerComponent } from './customer/edit-customer/edit-customer.component';
registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavtabsComponent,
    NavComponent,
    OrderDetailsComponent,
    SubHeaderComponent,
    SidebarComponent,
    AddProductComponent,
    EditProductComponent,
    DashboardComponent,
    OrderListComponent,
    PaymentComponent,
    ProductListComponent,
    AddCustomerComponent,
    EditCustomerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgZorroAntdMobileModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [ModalServiceComponent, ToastComponent, ActionSheetComponent]
})
export class AppModule { }
