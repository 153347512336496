import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  // disabled: boolean = false;
  // renderFooter: Function;

  productData:any;

  gridData = [];
  statusData = Array.from(new Array(4)).map((_val, i) => ({
    icon: '/assets/wait-icon.svg',
    text: `name${i}`
  }));
  quickLink = Array ({
    icon: '/assets/wait-icon.svg',
    text: `manoj`
  },
  {
    icon: '/assets/wait-icon.svg',
    text: `ganesh`
  });
  
  constructor() {
    this.productData = [
      {
        name: '20 Liter Can',
        disc: 'Available only chill water',
        price: 'Rs.50',
        priceDisc: 'Rs.150 per can',
        image: './../assets/product/water_jar.svg',
        url: '#'
      },
      {
        name: '25 Liter Jar',
        disc: 'Available in chill and normal water',
        price: 'Rs.50',
        priceDisc: 'Rs.500 per can',
        image: './../assets/product/water_can.svg',
        url: '#'
      },
      {
        name: '5 Liter Bottle',
        disc: 'Available in chill and normal water',
        price: 'Rs.20',
        priceDisc: '',
        image: './../assets/product/water_bottle_5.svg',
        url: '#'
      },
      {
        name: '2 Liter Bottle',
        disc: 'Available in chill and normal water',
        price: 'Rs.10',
        priceDisc: '',
        image: './../assets/product/water_bottle_1.svg',
        url: '#'
      },
      {
        name: '1 Liter Bottle',
        disc: 'Available in chill and normal water',
        price: 'Rs.5',
        priceDisc: '',
        image: './../assets/product/water_bottle_1.svg',
        url: '#'
      },
    ]
  }
  

  // renderHeader() {
  //   return 'Basic Style';
  // }

  // renderHeader1() {
  //   return 'Subtitle';
  // }

  // renderHeader2() {
  //   return 'Customized Right Side（Empty Content / Text / Image）';
  // }

  // renderHeader3() {
  //   return 'Align Vertical Center';
  // }

  // renderHeader4() {
  //   return 'Icon in the left';
  // }

  // renderHeader5() {
  //   return 'Text Wrapping';
  // }

  // renderHeader6() {
  //   return 'Other';
  // }

  onClick() {
    // console.log('click');
    location.href='/order-details';
  }

  // switchCheck(value) {
  //   console.log('switch status:', value);
  // }

  // onDisableClick() {
  //   console.log('click', this.disabled);
  //   this.disabled = true;
  // }
}
