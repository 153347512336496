import { Component } from '@angular/core';

@Component({
  selector: 'navtabs',
  templateUrl: './navtabs.component.html'
})
export class NavtabsComponent {
  index = 0;

  onChange(item) {
    console.log('onChange', item);
  }

  onTabClick(item) {
    console.log('onTabClick', item);
  }
}
