import { Component, ElementRef, Renderer } from '@angular/core';

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent {
  disabled: boolean = false;
  renderFooter: Function;

// SEARCH
  value: string = 'OK';
  autoFocus = {
    focusValue: true
  };
  focusObj = {
    focusValue: false,
    date: new Date()
  };
  orderStatus:any;
  constructor(private _element: ElementRef, private _renderer: Renderer) {


    this.orderStatus = [
      {
        id: '#PW12345ID',
        badge: 'Running Late',
        order: '20L X 3 Jar',
        mode: 'COD',
        date: '2nd Feb',
        time: '8:00 - 10:00 (AM)',
        amount:'Rs.600',
        assignTo:'Avinash'
      },
      {
        id: '#PW12345ID',       
        badge: 'Approved',
        order: '20L X 3 Jar',
        mode: 'COD',
        date: '2nd Feb',
        time: '8:00 - 10:00 (AM)',
        amount:'Rs.600',
        assignTo:'Avinash'
      },
      {
        id: '#PW12345ID',      
        badge: 'Delivered',
        order: '20L X 3 Jar',
        mode: 'COD',
        date: '2nd Feb',
        time: '8:00 - 10:00 (AM)',
        amount:'Rs.600',
        assignTo:'Avinash'
      },
      {
        id: '#PW12345ID',        
        badge: 'Running Late',
        order: '20L X 3 Jar',
        mode: 'COD',
        date: '2nd Feb',
        time: '8:00 - 10:00 (AM)',
        amount:'Rs.600',
        assignTo:'Avinash'
      },
      {
        id: '#PW12345ID',        
        badge: 'Running Late',
        order: '20L X 3 Jar',
        mode: 'COD',
        date: '2nd Feb',
        time: '8:00 - 10:00 (AM)',
        amount:'Rs.600',
        assignTo:'Avinash'
      },
    ]
  }

  renderHeader() {
    return 'Basic Style';
  }

  renderHeader1() {
    return 'Subtitle';
  }

  renderHeader2() {
    return 'Customized Right Side（Empty Content / Text / Image）';
  }

  renderHeader3() {
    return 'Align Vertical Center';
  }

  renderHeader4() {
    return 'Icon in the left';
  }

  renderHeader5() {
    return 'Text Wrapping';
  }

  renderHeader6() {
    return 'Other';
  }

  onClick() {
    console.log('click');
  }

  switchCheck(value) {
    console.log('switch status:', value);
  }

  onDisableClick() {
    console.log('click', this.disabled);
    this.disabled = true;
  }

  // for serch
  change($event) {
    console.log($event, 'onChange');
  }

  submit(value) {
    console.log(value, 'onSubmit');
  }

  clear(value) {
    console.log(value, 'onClear');
  }

  focus() {
    console.log('onFocus');
  }

  blur() {
    console.log('onBlur');
  }

  cancel() {
    console.log('onCancel');
  }

  handleClick() {
    this.focusObj = {
      focusValue: true,
      date: new Date()
    };
  }

}
