import { Component, ElementRef, Renderer } from '@angular/core';

@Component({
  selector: 'payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent {
  disabled: boolean = false;
  renderFooter: Function;

// SEARCH
  value: string = 'OK';
  autoFocus = {
    focusValue: true
  };
  focusObj = {
    focusValue: false,
    date: new Date()
  };
  orderStatus:any;
  constructor(private _element: ElementRef, private _renderer: Renderer) {


    this.orderStatus = [
      {
        name: 'Ganesh Borse',
        mobile: '+91 98123456678',
        amount:'Rs.600'
      },
      {
        name: 'Ganesh Borse',
        mobile: '+91 98123456678',
        amount:'Rs.600'
      },
      {
        name: 'Ganesh Borse',
        mobile: '+91 98123456678',
        amount:'Rs.600'
      },
      {
        name: 'Ganesh Borse',
        mobile: '+91 98123456678',
        amount:'Rs.600'
      },
      {
        name: 'Ganesh Borse',
        mobile: '+91 98123456678',
        amount:'Rs.600'
      },
    ]
  }

  renderHeader() {
    return 'Basic Style';
  }

  renderHeader1() {
    return 'Subtitle';
  }

  renderHeader2() {
    return 'Customized Right Side（Empty Content / Text / Image）';
  }

  renderHeader3() {
    return 'Align Vertical Center';
  }

  renderHeader4() {
    return 'Icon in the left';
  }

  renderHeader5() {
    return 'Text Wrapping';
  }

  renderHeader6() {
    return 'Other';
  }

  onClick() {
    console.log('click');
  }

  switchCheck(value) {
    console.log('switch status:', value);
  }

  onDisableClick() {
    console.log('click', this.disabled);
    this.disabled = true;
  }

  // for serch
  change($event) {
    console.log($event, 'onChange');
  }

  submit(value) {
    console.log(value, 'onSubmit');
  }

  clear(value) {
    console.log(value, 'onClear');
  }

  focus() {
    console.log('onFocus');
  }

  blur() {
    console.log('onBlur');
  }

  cancel() {
    console.log('onCancel');
  }

  handleClick() {
    this.focusObj = {
      focusValue: true,
      date: new Date()
    };
  }

}
